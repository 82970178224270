<template>
  <div>
    <v-tabs
      class="custom-navbar"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      v-if="isAuthenticated"
      v-model="selected_tab"
    >
      <template v-for="item in !isGPV && !isStaff ? mainTabs : gpv_staff_Tabs">
        <v-tab :key="item.key">
          <router-link :to="item.link">{{ item.label }}</router-link>
        </v-tab>
      </template>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "workday",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isGPV",
      "isBrand",
      "isStaff",
      "user",
    ]),
    calculatorTab() {
      return {
        label: this.$t("My Workday"),
        key: 1,
        link: "/workday/calculator",
      };
    },
    extractTab() {
      return {
        label: this.$t("Extract"),
        key: 2,
        link: "/workday/extract",
      };
    },
    mainTabs() {
      let tabs = [this.calculatorTab, this.extractTab];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    gpv_staff_Tabs() {
      let tabs = [this.calculatorTab, this.extractTab];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    selected_tab: {
      set(selected_tab) {},
      get() {
        let selected_tab_arr = this.gpv_staff_Tabs;
        if (!this.isGPV && !this.isStaff) {
          selected_tab_arr = this.mainTabs;
        }
        console.log("this.selected_tab_arr - ", selected_tab_arr);
        console.log(this.$route.fullPath);
        let route_fullpath = this.$route.fullPath;
        let paths = this.$route.fullPath.split("/");
        let selected_tab = selected_tab_arr.filter(
          (tab_item) => this.$route.fullPath === tab_item.link
        );
        if (selected_tab.length === 0) {
          selected_tab = selected_tab_arr.filter((tab_item) =>
            this.$route.fullPath.includes(tab_item.link)
          );
        }
        if (selected_tab.length > 0) {
          return selected_tab[0].key;
        } else {
          return selected_tab_arr[0].key;
        }
      },
    },
  },
  mounted() {},
};
</script>

<style></style>
